import "./ArticlesPage.scss";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Pl from "../../components/Pl/Pl";
import { Modal } from "antd";
import { BsTrash } from "react-icons/bs";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import {Upload} from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import ImageUploader from "quill-image-uploader";

Quill.register('modules/htmlEditButton', htmlEditButton);
Quill.register("modules/imageUploader", ImageUploader);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const asyncRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const ArticlesPage = () => {
  const { token } = useSelector((state) => state);
  const navigate = useNavigate();
  const [isViewArticleModalOpen, setIsViewArticleModalOpen] = useState(false);
  const [isEditArticleModalOpen, setIsEditArticleModalOpen] = useState(false);
  const [localTitle, setLocalTitle] = useState("");
  const [localDescr, setLocalDescr] = useState("");
  const [localImage, setLocalImage] = useState(null);
  const [modalTitle, setModaltitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [modalId, setModalId] = useState(null);
  const [pageContent, setPageContent] = useState([]);
  const [editorImages, setEditorImages] = useState([]);
  const showViewArticleModal = (title, content, image, id) => {
    setModaltitle(title);
    const replaceNbspWithBr = (htmlString) => {
      return htmlString.replace(/(&nbsp;)+/g, '<br/>');
    };
    setModalContent(replaceNbspWithBr(content))
    setModalImage(image);
    setTempImage(image);
    setModalId(id);
    setIsViewArticleModalOpen(true);
  };
  const handleViewArticleModalCancel = () => {
    setIsViewArticleModalOpen(false);
  };
  const showEditArticleModal = () => {
    setIsEditArticleModalOpen(true);
  };
  const handleEditArticleModalCancel = () => {
    setIsEditArticleModalOpen(false);
  };

  const getArticles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/news/get_news`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ entity_id: 2 }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      setPageContent(responseData);
      console.log(responseData);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  const editArticles = async (id) => {
    try {
      // const updatedPageContent = pageContent.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, name: localTitle, html: localDescr, image: localImage };
      //   }
      //   return item;
      // });
      // const requestBody = {
      //   page: "Articles",
      //   content: {
      //     ru: updatedPageContent,
      //   },
      // };

      const d = new FormData();
      d.append("status", 1);
      d.append("entity_id", 2);
      if (modalImage) {
        d.append("image", modalImage);
      }
      d.append("name", modalTitle);
      d.append("html", modalContent);
      d.append("id", modalId);

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/news/set_news`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: d,
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({ message: "Статья успешно отредактирована" });
      handleEditArticleModalCancel();
      setLocalTitle("");
      setLocalDescr("");
      setLocalImage("");
      setEditorImages([]);
      getArticles();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const handleUpload = async (info) => {
    console.log(info.file.status);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempImage(blobImg);
      setModalImage(base64);
      setLocalImage(base64);
    }
  };

  const handleDeleteArticle = async (id) => {
    try {
      const d = new FormData();
      d.append("id", id);

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/news/delete_news`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: d,
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({ message: "Статья успешно удалена" });
      handleViewArticleModalCancel();
      setLocalTitle("");
      setLocalDescr("");
      setLocalImage("");
      getArticles();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    htmlEditButton: {},
    // imageUploader: {
    //   upload: async (file) => {
    //       const base64 = await toBase64(file);
    //       const formData = new FormData();
    //       formData.append("image", base64);
          
    //       fetch(
    //         `${process.env.REACT_APP_HOST}/NewAdminPanel/news/uploadImage`,
    //         {
    //           method: "POST",
    //           headers: {
    //             // "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //           },
    //           body: formData
    //         }
    //       )
    //         .then((response) => response.json())
    //         .then((result) => {
    //           // setEditorImages([...editorImages, result])
    //           // setEditorImages(result);
    //           console.log(result);
    //           // resolve(result.data.url);
    //         })
    //         .catch((error) => {
    //           console.error("Error:", error);
    //         });
    //   }
    // }
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    // "imageBlot"
  ];

  useEffect(() => {
    const regex = /<img\s+src="(data:image\/[^;]+;base64,([^"]+))"/g;
    const base64DataArray = [];
    let matches;
    while ((matches = regex.exec(modalContent)) !== null) {
      console.log(matches)
      // base64DataArray.push(matches[1]);
      // console.log(matches[1])
      // const base64 = toBase64(matches[1]);
          const formData = new FormData();
          formData.append("image", matches[1]);
          
          fetch(
            `${process.env.REACT_APP_HOST}/NewAdminPanel/news/uploadImage`,
            {
              method: "POST",
              headers: {
                // "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: formData
            }
          )
            .then((response) => response.json())
            .then((result) => {
              setEditorImages([...editorImages, result])
             
              // setEditorImages(result);
              console.log(result);
              // base64DataArray.push(matches[1]);
              // resolve(result.data.url);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
  }
const reg = /<img\s+src="([^"]+)"/g;
  let matc;
  let index = 0;
  while ((matc = reg.exec(modalContent)) !== null && index < editorImages.length) {
      // setModalContent(modalContent.replace(matc[0], '<img src="' + editorImages[editorImages.length-1] + '"'))
      // index++;
      const newUrl = editorImages[index]; // Получаем новый URL изображения из editorImages
    const oldUrl = matc[1]; // Получаем старый URL изображения из совпадения регулярного выражения
    setModalContent(modalContent.replace(oldUrl, newUrl)); // Заменяем старый URL на новый
    index++;
  }
  console.log(modalContent);
  }, [modalContent])




  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="ArticlePage__body pageBody-content">
          <div className="ArticlePage__button">
          <Button
            styles={{ marginBottom: "30px" }}
            text={"Добавить"}
            onClick={() => {
              navigate("/articles/create");
            }}
          ></Button>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "30px",
            }}
          >
            {pageContent
              ? pageContent.map((item) => (
                  <Pl
                    style={{
                      width: "100%",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                    text={item.name}
                    onClick={() =>
                      showViewArticleModal(item.name, item.html, item.image, item.id)
                    }
                  ></Pl>
                ))
              : null}
          </div>
          <Modal
            open={isViewArticleModalOpen}
            title={modalTitle}
            onCancel={handleViewArticleModalCancel}
            footer={[]}
            width={780}
          >
            <div className="ArticlePage__modal-text">Изображение</div>
            <img className="ArticlePage__modal-img" src={modalImage} alt="" />
            <div className="ArticlePage__modal-text">Статья</div>
            <p className="ArticlePage__modal-descr">
              {modalContent ? parse(modalContent) : null}
            </p>
            <div className="ArticlePage__modal-wrapper">
              <Button
                key="back"
                onClick={() => handleDeleteArticle(modalId)}
                text={"Удалить"}
                variant={"danger"}
                before={<BsTrash />}
              />
              <Button
                key="back"
                onClick={showEditArticleModal}
                text={"Редактировать"}
              />
            </div>
            <Modal
              open={isEditArticleModalOpen}
              title="Редактировать"
              onCancel={handleEditArticleModalCancel}
              footer={[]}
              width={880}
            >
              <div className="ArticlePage__item">
                {/* <div className="ArticlePage__item-label">Изображение</div> */}
                {tempImage ? (
                    <div className="banners__pic-item ArticlePage__item-pic">
                      <img src={tempImage} alt="" />
                      <Upload
                        className="baners__pic-item--new"
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p>Загрузить другую картинку</p>
                      </Upload>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
              </div>
              <div className="ArticlePage__item">
                <div className="ArticlePage__item-label">Заголовок</div>
                <Input
                  style={{
                    border: "1.5px solid rgba(176, 194, 255, .6)",
                    borderRadius: "13px",
                  }}
                  maskType={String}
                  shadow
                  onChange={(e) => {
                    setModaltitle(e.target.value);
                    setLocalTitle(e.target.value);
                  }}
                  value={modalTitle}
                />
              </div>
                <div className="ArticlePage__item-label">Описание</div>
                {/* <TextArea
                  className="ArticlePage__textarea"
                  value={modalContent}
                  onChange={(e) => {
                    setModalContent(e.target.value);
                    setLocalDescr(e.target.value);
                  }}
                  rows={4}
                ></TextArea> */}
                {/* {function handleQuilValueChange(value) {
                  setTimeout(() => {
                    setModalContent(value)
                  }, timeout);
                }} */}
                <ReactQuill
                  // defaultValue={modalContent ? modalContent : null}
                  value={modalContent}
                  onChange={(e) => {
                    setTimeout(() => {
                      console.log(e)
                      setModalContent(e);
                      setLocalDescr(e)
                    }, 0);
                  }}
                  style={{ height: "200px", color: "black" }}
                  formats={formats}
                  modules={modules}
                />
              <div style={{marginTop: '90px'}} className="ArticlePage__modal-wrapper">
                <Button
                  key="back"
                  onClick={handleEditArticleModalCancel}
                  text={"Отмена"}
                  variant={"danger"}
                />
                <Button
                  key="back"
                  onClick={() => editArticles(modalId)}
                  text={"Сохранить"}
                />
              </div>
            </Modal>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default ArticlesPage;
