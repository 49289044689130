import { Button, Modal, Upload } from "antd";
import { useEffect, useState } from "react";
import endpoints from "../../services/endpoints";
import checkAuth from "../../services/checkAuth";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { TiPlusOutline } from "react-icons/ti";
import { BiPlus } from "react-icons/bi";
import Input from "../../components/Input/Input";
import SaveIcon from "../../icons/SaveIcon/SaveIcon";
import checkDom from "../../funcs/checkDom";
import "./Banners.scss";
import Btn from "../../components/Button/Button";
import { motion } from "framer-motion";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const asyncRequest = async ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const Banners = (props) => {
  const { token, settings } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [squareBannersData, setSquareBannersData] = useState([]);
  const [rectangularBannersData, setRectangularBannersData] = useState([]);
  const [image, setImage] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [editImage, setEditImage] = useState("");
  const [tempEditImage, setTempEditImage] = useState("");
  const [bannerEditText, setBannerEditText] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSquareBannerModalOpen, setIsSquareBannerModalOpen] = useState(false);
  const [isRectangularBannerModalOpen, setIsRectangularBannerModalOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showSquareBannerModal = () => {
    setIsSquareBannerModalOpen(true);
  };
  const showRectangularBannerModal = () => {
    setIsRectangularBannerModalOpen(true);
  };

  const handleEditBanner = () => {
    setIsEditModalOpen(true);
  };
  const handleCancelEditBanner = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    addBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSquareBannerSave = () => {
    addSquareBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleSquareBannerCancel = () => {
    setIsSquareBannerModalOpen(false);
    handleCancel();
  };

  const handleRectangularBannerSave = () => {
    addRectangularBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleRectangularBannerCancel = () => {
    setIsRectangularBannerModalOpen(false);
    handleCancel();
  };



  const fetchData = async () => {
    try {
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setData(d);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchSquareBannersData = async () => {
    try {
        const formData = new FormData();
      formData.append("type", 1);
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setSquareBannersData(d);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchRectangularBannersData = async () => {
    try {
      const formData = new FormData();
      formData.append("type", 0);
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setRectangularBannersData(d);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    if (window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
        fetchSquareBannersData();
        fetchRectangularBannersData();
    }
    if (window.location.origin === 'https://ibazar.gscore.ru') {
      fetchRectangularBannersData();
    }
  }, []);

  const addBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      if (window.location.origin === "https://ibazar.gscore.ru" || window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
        formData.append("text", bannerText);
      }
      
      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchData();
  };

  const addSquareBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      if (window.location.origin === "https://ibazar.gscore.ru" || window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
        formData.append("text", bannerText);
    }
        if (window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
            formData.append("type", 1);
          formData.append("url", bannerUrl);
      }

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchSquareBannersData();
  };
  const addRectangularBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      if (window.location.origin === "https://ibazar.gscore.ru" || window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
        formData.append("text", bannerText);
      }
      if (window.location.origin === 'https://mama.gscore.ru' || window.location.origin === 'https://iiko.gscore.ru' || window.location.origin === 'https://testjonny.gscore,ru' || window.location.origin === 'https://osaka.gscore.ru' || window.location.origin === 'http://localhost:3000') {
            formData.append("type", 0);
          formData.append("url", bannerUrl);
      }

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchRectangularBannersData();
  };

  const editBanner = async (id) => {
      try {
          const formData  = new FormData();

          formData.append('width', 250)
          formData.append('height', 150)
          formData.append('image', editImage)
          formData.append('text', bannerEditText)

          let res = await fetch(endpoints.addBanners, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
              body: formData
          })

          const d = await checkAuth(res)
          fetchData()
      } catch(err) {
          console.log(err)
      }
  }

  const handleUpload = async (info) => {
    console.log(info.file.status);
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempImage(blobImg);
      setImage(base64);
    }
  };
  const handleEditUpload = async (info) => {
    console.log(info.file.status);
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempEditImage(blobImg);
      setEditImage(base64);
    }
  };

  const handleDelete = async (id) => {
    try {
      const formData = new FormData();
      formData.append("ID", id);

      let res = await fetch(endpoints.removeBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
      fetchData();
      fetchSquareBannersData();
      fetchRectangularBannersData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSquareBannerOrderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    console.log(sourceIndex, targetIndex, sourceId, targetId)
    if (sourceIndex === squareBannersData.length) {
      return;
    } else {
      const nextState = swap(squareBannersData, sourceIndex, targetIndex);
      setSquareBannersData(nextState);
    }
  };
  const handleRectangularBannerOrderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    console.log(sourceIndex, targetIndex, sourceId, targetId)
    if (sourceIndex === rectangularBannersData.length) {
      return;
    } else {
      const nextState = swap(rectangularBannersData, sourceIndex, targetIndex);
      setRectangularBannersData(nextState);
    }
  };
  const handleDataBannerOrderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    console.log(sourceIndex, targetIndex, sourceId, targetId)
    if (sourceIndex === data.length) {
      return;
    } else {
      const nextState = swap(data, sourceIndex, targetIndex);
      setData(nextState);
    }
  };


  // window.location.origin === "http://localhost:3000"

  if (
    window.location.origin === "https://mama.gscore.ru" || window.location.origin === "https://iiko.gscore.ru" || window.location.origin === "https://testjonny.gscore.ru/" || window.location.origin === "https://osaka.gscore.ru" || window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'http://localhost:3000'
  ) {
    return (
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="pageBody-content">
        <div style={{ display: "flex", justifyContent: 'center', marginTop: '50px' }}>
          {window.location.origin === 'https://ibazar.gscore.ru' ? null : <Btn
            styles={{ marginRight: "20px" }}
            text={"Квадратные баннеры"}
            onClick={showSquareBannerModal}
          ></Btn>}
          <Modal
            style={{overflow: 'scroll'}}
            open={isSquareBannerModalOpen}
            onCancel={handleSquareBannerCancel}
            footer={<></>}
            width={1200}
          >
            <h2>Редактировать квадратные баннеры</h2>
            {/* <div
              style={{
                margin: "30px 0",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
              }}
            > */}
              {/* {squareBannersData?.map((c, i) => (
                <div key={i}>
                  <div
                    style={{
                      border: "2px solid gray",
                      width: c.width,
                      height: c.height,
                      padding: 12,
                      borderRadius: "12px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -12,
                        cursor: "pointer",
                        right: -12,
                        background: "#DC143C",
                        height: 26,
                        width: 26,
                        borderRadius: "50%",
                        color: "white",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "24px",
                      }}
                      onClick={() => handleDelete(c.ID)}
                    >
                      x
                    </div>
                    <img src={`${c.image}`} width={c.width} />
                  </div>
                </div>
              ))} */}
              <GridContextProvider onChange={handleSquareBannerOrderChange}>
      <GridDropZone
        boxesPerRow={4}
        rowHeight={400}
        style={{ height: squareBannersData.length > 0 ? '800px' : '200px' }}
      >
        {squareBannersData?.map((c, i) => (
          <GridItem key={i}>
            <div style={{width: c.width, height: c.height, display: 'block'}}>
            <div
              style={{
                border: "2px solid gray",
                width: c.width,
                height: c.height,
                padding: 12,
                borderRadius: "12px",
                position: "relative",
                margin: 12,
                pointerEvents: 'none'
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -12,
                  cursor: "pointer",
                  right: -12,
                  background: "#DC143C",
                  height: 26,
                  width: 26,
                  borderRadius: "50%",
                  color: "white",
                  fontWeight: 500,
                  textAlign: "center",
                  lineHeight: "24px",
                  pointerEvents: 'auto'
                }}
                onClick={() => handleDelete(c.ID)}
              >
                x
              </div>
              <img src={`${c.image}`} width={c.width} />
            </div>
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
              <div style={{ width: 250, height: "250px" }}>
                <Modal
                  title="Добавить квадратный баннер"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  width={480}
                  footer={[
                    <button
                      type={"button"}
                      onClick={handleSquareBannerSave}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(123, 153, 255)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Сохранить
                    </button>,
                    <button
                      type={"button"}
                      onClick={handleCancel}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(255, 77, 79)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                      }}
                    >
                      Отмена
                    </button>,
                  ]}
                >
                  {tempImage ? (
                    <div className="banners__pic-item">
                      <img src={tempImage} alt="" />
                      <Upload
                        className="baners__pic-item--new"
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p>Загрузить другую картинку</p>
                      </Upload>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
                  {window.location.origin === "https://ibazar.gscore.ru" ||
                  window.location.origin === "https://mama.gscore.ru" || window.location.origin === "https://iiko.gscore.ru" || window.location.origin === "https://testjonny.gscore.ru/" || window.location.origin === "https://osaka.gscore.ru" ||
                  window.location.origin === 'http://localhost:3000' ? (
                    <>
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerText}
                        onChange={(e) => setBannerText(e.target.value)}
                        placeholder="Название"
                        style={{ marginTop: "20px" }}
                      />
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerUrl}
                        onChange={(e) => setBannerUrl(e.target.value)}
                        placeholder="URL"
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  ) : null}
                </Modal>
                <button
                  onClick={showModal}
                  style={{
                    height: "150px",
                    border: "1px dashed #aaa",
                    background: "none",
                    outline: "none",
                    cursor: "pointer",
                    backgroundColor: "#eee",
                    padding: "90px 50px 140px",
                  }}
                  type="button"
                >
                  <BiPlus size={"24px"} color={"#7B99FF"} />
                  <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                    Добавить баннер
                  </div>
                </button>
              </div>
              {/*<input type={'file'} onChange={handleUpload} />*/}
            {/* </div> */}
            <Button
              onClick={handleSquareBannerCancel}
              danger
              type={"primary"}
              style={{ marginLeft: "auto", display: "block" }}
            >
              Закрыть
            </Button>
          </Modal>

          <Btn
            styles={{ display: "inline-flex" }}
            text={"Прямоугольные баннеры"}
            onClick={showRectangularBannerModal}
          ></Btn>
          <Modal
            open={isRectangularBannerModalOpen}
            onCancel={handleRectangularBannerCancel}
            footer={<></>}
            width={1200}
          >
            <h2>Редактировать прямоугольные баннеры</h2>
            {/* <div
              style={{
                margin: "30px 0",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
              }}
            >
              {rectangularBannersData?.map((c, i) => (
                <div key={i}>
                  <div
                    style={{
                      border: "2px solid gray",
                      width: c.width,
                      height: c.height,
                      padding: 12,
                      borderRadius: "12px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -12,
                        cursor: "pointer",
                        right: -12,
                        background: "#DC143C",
                        height: 26,
                        width: 26,
                        borderRadius: "50%",
                        color: "white",
                        fontWeight: 500,
                        textAlign: "center",
                        lineHeight: "24px",
                      }}
                      onClick={() => handleDelete(c.ID)}
                    >
                      x
                    </div>
                    <img src={`${c.image}`} width={c.width} />
                  </div>
                </div>
              ))} */}
              <GridContextProvider onChange={handleRectangularBannerOrderChange}>
      <GridDropZone
        boxesPerRow={4}
        rowHeight={200}
        style={{ height: rectangularBannersData.length > 0 ? '400px' : '200px' }}
      >
        {rectangularBannersData?.map((c, i) => (
          <GridItem key={i}>
            <div style={{width: c.width, height: c.height, display: 'block'}}>
            <div
              style={{
                border: "2px solid gray",
                width: c.width,
                height: c.height,
                padding: 12,
                borderRadius: "12px",
                position: "relative",
                margin: 12,
                pointerEvents: 'none'
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -12,
                  cursor: "pointer",
                  right: -12,
                  background: "#DC143C",
                  height: 26,
                  width: 26,
                  borderRadius: "50%",
                  color: "white",
                  fontWeight: 500,
                  textAlign: "center",
                  lineHeight: "24px",
                  pointerEvents: 'auto'
                }}
                onClick={() => handleDelete(c.ID)}
              >
                x
              </div>
              <img src={`${c.image}`} width={c.width} />
            </div>
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
              <div style={{ width: 250, height: "250px" }}>
                <Modal
                  title="Добавить прямоугольный баннер"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  width={480}
                  footer={[
                    <button
                      type={"button"}
                      onClick={handleRectangularBannerSave}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(123, 153, 255)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Сохранить
                    </button>,
                    <button
                      type={"button"}
                      onClick={handleCancel}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(255, 77, 79)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                      }}
                    >
                      Отмена
                    </button>,
                  ]}
                >
                  {tempImage ? (
                    <div className="banners__pic-item">
                      <img src={tempImage} alt="" />
                      <Upload
                        className="baners__pic-item--new"
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p>Загрузить другую картинку</p>
                      </Upload>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
                  {window.location.origin === "https://ibazar.gscore.ru" ||
                  window.location.origin === "https://mama.gscore.ru" || window.location.origin === "https://iiko.gscore.ru" || window.location.origin === "https://testjonny.gscore.ru/" || window.location.origin === "https://osaka.gscore.ru" ||
                  window.location.origin === 'http://localhost:3000' ? (
                    <>
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerText}
                        onChange={(e) => setBannerText(e.target.value)}
                        placeholder="Название"
                        style={{ marginTop: "20px" }}
                      />
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerUrl}
                        onChange={(e) => setBannerUrl(e.target.value)}
                        placeholder="URL"
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  ) : null}
                </Modal>
                <button
                  onClick={showModal}
                  style={{
                    height: "150px",
                    border: "1px dashed #aaa",
                    background: "none",
                    outline: "none",
                    cursor: "pointer",
                    backgroundColor: "#eee",
                    padding: "90px 50px 140px",
                  }}
                  type="button"
                >
                  <BiPlus size={"24px"} color={"#7B99FF"} />
                  <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                    Добавить баннер
                  </div>
                </button>
              </div>
              {/*<input type={'file'} onChange={handleUpload} />*/}
            {/* </div> */}
            <Button
              onClick={handleRectangularBannerCancel}
              danger
              type={"primary"}
              style={{ marginLeft: "auto", display: "block" }}
            >
              Закрыть
            </Button>
          </Modal>
        </div>
        </div>
        </div>
        </motion.div>
    );
  } else {
    return (
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="pageBody-content">
        <h2>Редактировать баннеры</h2>
        <GridContextProvider onChange={handleDataBannerOrderChange}>
      <GridDropZone
        boxesPerRow={4}
        rowHeight={200}
        style={{ height: data.length > 0 ? '400px' : '200px' }}
      >
        {data?.map((c, i) => (
          <GridItem key={i}>
            <div style={{width: c.width, height: c.height, display: 'block'}}>
            <div
              style={{
                border: "2px solid gray",
                width: c.width,
                height: c.height,
                padding: 12,
                borderRadius: "12px",
                position: "relative",
                margin: 12,
                pointerEvents: 'none'
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -12,
                  cursor: "pointer",
                  right: -12,
                  background: "#DC143C",
                  height: 26,
                  width: 26,
                  borderRadius: "50%",
                  color: "white",
                  fontWeight: 500,
                  textAlign: "center",
                  lineHeight: "24px",
                  pointerEvents: 'auto'
                }}
                onClick={() => handleDelete(c.ID)}
              >
                x
              </div>
              <img src={`${c.image}`} width={c.width} />
            </div>
            </div>
          </GridItem>
        ))}
      </GridDropZone>
    </GridContextProvider>
        {/* <div
          style={{
            margin: "30px 0",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
          }}
        >
          {data?.map((c, i) => (
            <div key={i}>
              <div
                style={{
                  border: "2px solid gray",
                  width: c.width,
                  height: c.height,
                  padding: 12,
                  borderRadius: "12px",
                  position: "relative",
                  pointerEvents: 'none'
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: -12,
                    cursor: "pointer",
                    right: -12,
                    background: "#DC143C",
                    height: 26,
                    width: 26,
                    borderRadius: "50%",
                    color: "white",
                    fontWeight: 500,
                    textAlign: "center",
                    lineHeight: "24px",
                  }}
                  onClick={() => handleDelete(c.ID)}
                >
                  x
                </div>
                {
                  window.location.origin === 'https://ibazar.gscore.ru' ?
                  <img src={`${c.image}`} width={c.width} onClick={handleEditBanner} /> : null
                }
                <img src={`${c.image}`} width={c.width} />
              </div>
                
            </div>
          ))} */}
          <Modal 
                        title="Редактировать баннер" 
                        open={isEditModalOpen} 
                        onCancel={handleCancelEditBanner} 
                        width={480}
                        footer={[
                            <button 
                                type={'button'} 
                                onClick={editBanner}
                                style={{width: '100%', backgroundColor: 'rgb(123, 153, 255)', border: 'none', color: '#fff', fontWeight: '600', padding: '7px 0', cursor: 'pointer', marginBottom: '10px'}}
                            >
                                Сохранить
                            </button>,
                            <button 
                            type={'button'} 
                            onClick={handleCancelEditBanner}
                            style={{width: '100%', backgroundColor: 'rgb(255, 77, 79)', border: 'none', color: '#fff', fontWeight: '600', padding: '7px 0', cursor: 'pointer'}}
                            >
                                Отмена
                            </button>,
                        ]}>
                        {tempEditImage ? (
                <div className="banners__pic-item">
                  <img src={tempEditImage} alt="" />
                  <Upload
                    className="baners__pic-item--new"
                    showUploadList={false}
                    customRequest={asyncRequest}
                    listType="picture-card"
                    onChange={handleEditUpload}
                  >
                    <p>Загрузить другую картинку</p>
                  </Upload>
                </div>
              ) : (
                <Upload
                  showUploadList={false}
                  customRequest={asyncRequest}
                  listType="picture-card"
                  onChange={handleEditUpload}
                >
                  <p
                    style={{
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Загрузить картинку
                  </p>
                </Upload>
              )}
              {checkDom(
                "https://ibazar.gscore.ru",
                <Input
                  shadow={true}
                  maskType={String}
                  value={bannerEditText}
                  onChange={(e) => setBannerEditText(e.target.value)}
                  placeholder="Название"
                  style={{ marginTop: "20px" }}
                />
              )}
                    </Modal>
          <div style={{ width: 250, height: "250px" }}>
            <Modal
              title="Добавить баннер"
              open={isModalOpen}
              onCancel={handleCancel}
              width={480}
              footer={[
                <button
                  type={"button"}
                  onClick={handleSave}
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(123, 153, 255)",
                    border: "none",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "7px 0",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                >
                  Сохранить
                </button>,
                <button
                  type={"button"}
                  onClick={handleCancel}
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(255, 77, 79)",
                    border: "none",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "7px 0",
                    cursor: "pointer",
                  }}
                >
                  Отмена
                </button>,
              ]}
            >
              {tempImage ? (
                <div className="banners__pic-item">
                  <img src={tempImage} alt="" />
                  <Upload
                    className="baners__pic-item--new"
                    showUploadList={false}
                    customRequest={asyncRequest}
                    listType="picture-card"
                    onChange={handleUpload}
                  >
                    <p>Загрузить другую картинку</p>
                  </Upload>
                </div>
              ) : (
                <Upload
                  showUploadList={false}
                  customRequest={asyncRequest}
                  listType="picture-card"
                  onChange={handleUpload}
                >
                  <p
                    style={{
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Загрузить картинку
                  </p>
                </Upload>
              )}
              {checkDom(
                "https://ibazar.gscore.ru",
                <Input
                  shadow={true}
                  maskType={String}
                  value={bannerText}
                  onChange={(e) => setBannerText(e.target.value)}
                  placeholder="Название"
                  style={{ marginTop: "20px" }}
                />
              )}
            </Modal>
            <button
              onClick={showModal}
              style={{
                height: "150px",
                border: "1px dashed #aaa",
                background: "none",
                outline: "none",
                cursor: "pointer",
                backgroundColor: "#eee",
                padding: "90px 50px 140px",
              }}
              type="button"
            >
              <BiPlus size={"24px"} color={"#7B99FF"} />
              <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                Добавить баннер
              </div>
            </button>
          </div>
          {/*<input type={'file'} onChange={handleUpload} />*/}
        {/* </div> */}
        {/* <Button
          onClick={props.onClose}
          danger
          type={"primary"}
          style={{ marginLeft: "auto", display: "block" }}
        >
          Закрыть
        </Button> */}
        </div>
        </div>
      </motion.div>
    );
  }
};

export default Banners;